import { defineAsyncComponent } from "vue";
export default [
  {
    path: "/order/:id",
    name: "order-id-template",
    title: "Заказы Ultimatum Store",
    component: () => import("../template/pages/templatePagesId.vue"),
    meta: {
      collection: "order",
      populate: [
          {
          path: "items.productid",
          select: [
            "name",
            "activityspecifications",
            "specifications.artikul",
            "specifications.name",
            "specifications.barcode",
            "specifications._id",
            "images",
            "price",
            "artikul",
            "barcode",
            'tnved',
            'model'
          ],
          populate:[{path:'model',select:['marking']}]},
          {path: "delivery",select:['price']}
      ],
      template: {
        main: ["OrderEditor","OrderContragent","OrderProduct","OrderDelivery","OrderPayment"],
        sidebar: ["OrderStatus"],
      },
    },
  },
  {
    path: "/order",
    name: "order",
    title: "Заказы Ultimatum Store",
    component: () => import("../template/pages/templateCollection.vue"),
    meta: {
      infocollection: {
        name: "Заказы",
        item: defineAsyncComponent(() => import("@/components/order/item.vue")),
      },
      collection: "order",
      populate: [
        {
          path: "contragent",
          select: ["firstname", "lastname", "phone", "avatar"],
        },
        {
          path: "items.productid",
          select: [
            "name",
            "activityspecifications",
            "specifications.artikul",
            "specifications.name",
            "specifications.barcode",
            "specifications._id",
            "images",
            "price",
            "artikul",
            "barcode",
            'tnved'
          ],
        },
        { path: "delivery" },
        { path: "payment" },
        { path:"checkavans",select:['status']},
        { path:"checkfull",select:['status']},
        { path:"refferal",select:['firstname','lastname','_id']}
      ],
      sort:{ordernumber:-1},
      viewdocument:{type:{$ne:'certificates'}},
      filter: {
        object: ["ordernumber","crmnumber"],
        text: "Найти заказ по номеру",
        placeholder: "введите номер заказа",
         menufilter: [
          { name: "Все", key: "channel", action:['ultimatum-store','ultimatum-com','ultimatum-shop','ultimatum-sportmaster','ultimatum-crm']},
          { name: "Cайт(store)", key: "channel", action: "ultimatum-store"},
          { name: "Cайт(com)", key: "channel", action: "ultimatum-com"},
          { name: "Магазин", key: "channel", action: "ultimatum-shop"},
          { name: "Спортмастер", key: "channel", action: "ultimatum-sportmaster"},
          { name: "Crm", key: "channel", action: "ultimatum-crm"},
        ],
      },
    },
  },
  {
    path: "/returnorder",
    name: "returnorder",
    title: "Возвраты Ultimatum Store",
    component: () => import("../template/pages/templateCollection.vue"),
    meta: {
      infocollection: {
        name: "Возвраты",
        item: defineAsyncComponent(() =>
          import("@/components/returnorder/item.vue")
        ),
      },
      collection: "returnorder",
      populate: [
        {
        path: "contragent",
        select: ["firstname", "lastname", "phone", "avatar"],
       },
       {
        path: "order.items.productid",
        select: [
          "name",
          "activityspecifications",
          "specifications.artikul",
          "specifications.name",
          "specifications.barcode",
          "specifications._id",
          "images",
          "price",
          "artikul",
          "barcode",
          'tnved'
        ],
       },
       { path: "delivery" },
    ],
      filter: {
        object: ["id"],
        text: "Найти по номеру возврата",
        placeholder: "введите номер возврата",
        menufilter: [
          { name: "Создан", key: "status", action: true },
          { name: "В пути", key: "status", action: false },
          { name: "Ожидает", key: "status", action: false },
          { name: "Завершен", key: "status", action: false },
        ],
      },
    },
  },
  {
    path: "/returnorder/:id",
    name: "returnorder-id-template",
    title: "Заказы Ultimatum Store",
    component: () => import("../template/pages/templatePagesId.vue"),
    meta: {
      collection: "returnorder",
      populate: [
          {
          path: "order.items.productid",
          select: [
            "name",
            "activityspecifications",
            "specifications.artikul",
            "specifications.name",
            "specifications.barcode",
            "specifications._id",
            "images",
            "price",
            "artikul",
            "barcode",
            'tnved',
            'model'
          ],
          populate:[{path:'model',select:['marking']}]},
          {path: "delivery",select:['price']}
      ],
      template: {
        main: ["OrderEditorReturn","OrderContragent","OrderProductReturn","OrderPaymentReturn"],
        sidebar: ["OrderStatusReturn"],
      },
    },
  },
];

