import { defineAsyncComponent } from "vue";
export default [
  {
    path: "/markirovka",
    name: "markirovka",
    title: "Маркировка Ultimatum Store",
    component: () => import("../template/pages/templateCollection.vue"),
    meta: {
      infocollection: {
        name: "Маркировка",
        item: defineAsyncComponent(() => import("@/components/markirovka/item.vue")),
      },
      collection: "markirovka",
      filter: {
        object: ["marknumber"],
        text: "Найти по номеру",
        placeholder: "введите номер",
        menufilter: [],
      },
    },
  },
  {
    path: "/markirovka/:id",
    name: "markirovka-id",
    title: "Заказы Ultimatum Store",
    component: () => import("../template/pages/templatePagesId.vue"),
    meta: {
      collection: "markirovka",
      template: {
        main: ["Markmenu","Deletedocument"],
        sidebar: ["Markmenustatus"],
      },
    },
  },
];
